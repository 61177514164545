import * as React from 'react';
import { Button } from '../../../components/DesignSystemV2/Elements/Buttons/Button/Button';
import { styled } from '../../../components/Layout/ThemeProvider/ThemeProvider';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;

  font-family: ${({ theme: { fonts } }) => fonts.roboto};

  margin-top: 5rem;
`;

const SupportingHeadline = styled.p`
  color: ${({ theme: { colors } }) => colors.primary5};
  font-size: 1.4rem;
  line-height: 2rem;

  @media (${props => props.theme.deviceBreakpoints.mobile}) {
    margin-top: 1.4rem;
  }

  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 2.3rem;
    margin-top: 2rem;
  }
`;
const Headline = styled.h1`
  margin-top: 5rem;
  margin-bottom: .5rem;

  font-family: ${({ theme: { fonts } }) => fonts.robotoBold};
  font-size: 3.2rem;
  color: ${({ theme: { colors } }) => colors.primary5};

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    font-size: 4.8rem;
  }
  @media (${props => props.theme.deviceBreakpoints.desktop}) {
    font-size: 5.6rem;
  }
`;

const LeftContainer = styled.div`
  width: 100%;
  padding: 3rem 8rem 0;
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    width: 43%;
    padding-top: 6rem;
    padding-left: 14rem;
    padding-right: 0;
  }
`;

const RightContainer = styled.div`
  display: none;

  @media (${props => props.theme.deviceBreakpoints.laptop}) {
    width: 57%;
    display: flex;
    flex-direction: row;
  }
`;

const ArtboardImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ReadMoreButton = styled(Button)`
  width: 16.8rem;
  margin: 1.6rem 0 0;
`;

const headlineText = 'Fakturuj, to proste';
// tslint:disable-next-line:max-line-length
const supportingHeadlineText = 'Niezależnie czy chcesz wystawić fakturę sprzedaży czy fakturę zaliczkę, zawsze zrobisz to w sposób intuicyjny i ekspresowy!';
const ctaLabel = 'Czytaj więcej';

export const Hero: React.FC<{ artboardSrc?: string, className?: string }> = ({ artboardSrc, className }) => (
  <Container className={className}>
    <LeftContainer>
      <Headline>
        {headlineText}
      </Headline>
      <SupportingHeadline>
        {supportingHeadlineText}
      </SupportingHeadline>
      <ReadMoreButton>
        {ctaLabel}
      </ReadMoreButton>
    </LeftContainer>
    <RightContainer>
      <ArtboardImg src={artboardSrc}/>
    </RightContainer>
  </Container>
);

import { styled } from '../../../../Layout/ThemeProvider/ThemeProvider';
import { ComponentSize } from '../../../ComponentSize';
import {
  buttonDefaultWidth,
  buttonDisabledStyles,
  buttonLoadingStyles,
  buttonLookStyles,
  buttonSizeStyles,
} from '../mixins';
import { ButtonLook } from './Button';

type Props = {
  look: ButtonLook;
  size: ComponentSize;
  secondary: boolean;
  tertiary: boolean;
  disabled: boolean;
  defaultWidth: boolean;
  loading: boolean;
};

export const ButtonElement = styled.button<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: .5rem;
  border: 0;
  border-radius: 0.8rem;

  font-family: ${({ theme }) => theme.fonts.quicksandBold}, sans-serif;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};

  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  z-index: 0;

  > svg {
    opacity: 1;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: .9rem;
    transition: all 0.3s;
  }

  ${props => buttonSizeStyles(props.size)}
  ${props => buttonLookStyles({
  look: props.look,
  theme: props.theme,
  tertiary: props.tertiary,
  secondary: props.secondary,
  disabled: props.disabled,
})}
  ${props => buttonDefaultWidth(props.defaultWidth)}
  ${props => buttonDisabledStyles(props.disabled)}
  ${props => buttonLoadingStyles(props.loading)}
`;

export const RightComponentContainer = styled.div`
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
`;

import * as React from 'react';
import { styled } from '../../Layout/ThemeProvider/ThemeProvider';

type Props = {
  header: React.ReactNode;
  text: React.ReactNode;
  imageSrc: string;
  textFirst?: boolean;
  style?: React.CSSProperties;
};

const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.primary5};
`;

const HeadingH2 = styled.div`
  font-size: 4.8rem;
  font-family: ${props => props.theme.fonts.robotoBold};
  margin-bottom: 6rem;
  margin-top: 14rem;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  font-family: ${props => props.theme.fonts.roboto};
  margin: 0;
  line-height: 2rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.textFirst {
    flex: 1;
    ${Paragraph},
    ${HeadingH2}{
      width: max-content;
      align-self: flex-end;
    }
    ${HeadingH2}{
      margin-right: 4rem;
    }
  }
  &.textSecond {
    padding-left: 1rem;
  }
`;

export const TextImageSection: React.FC<Props> = ({
                                                    header,
                                                    imageSrc,
                                                    text,
                                                    style,
                                                    textFirst,
                                                  }) => {
  const Picture = (
    <Column>
      <img
        style={{ width: '77.2rem' }}
        src={imageSrc}
      />
    </Column>
  );

  const Text = (
    <Column className={textFirst ? 'textFirst' : 'textSecond'}>
      <HeadingH2>
        {header}
      </HeadingH2>
      <Paragraph>
        {text}
      </Paragraph>
    </Column>
  );

  if (textFirst) {
    return (
      <ContainerRow style={style}>
        {Text}
        {Picture}
      </ContainerRow>
    );
  }
  return (
    <ContainerRow style={style}>
      {Picture}
      {Text}
    </ContainerRow>
  );
};

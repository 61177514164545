import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';

const cloudImg = require('./assets/cloud.png');
const robustnessImg = require('./assets/robustness.png');
const securityImg = require('./assets/security.png');

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
  margin-top: 6rem;
  margin-bottom: 12rem;
`;

const ElementItem = styled.div`
  margin: 2rem 3.6rem 2rem 3.5rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  
  width: 325px;
  height: 391px;
  background: #ffffff 0 0 no-repeat padding-box;
  border: 2px solid #084bbe;
  border-radius: 23px;
  opacity: 1;
`;

const Image = styled.img`
  width: 17.1rem;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.robotoBold}, sans-serif;
  font-size: 2.3rem;
  text-align: center;
  letter-spacing: 0;
  color: #062861;
`;

const Description = styled.div`
  margin-top: 3rem;
  
  font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
  line-height: 2.3rem;
  font-size: 1.6rem;
  text-align: center;
  color: #062861;
`;

export const AdditionalBenefits = () => (
  <Container>
    <ElementItem>
      <Image
        css={`
          margin-top: .2rem;
          margin-right: .6rem;
        `}
        src={cloudImg}
      />
      <Title
        css={`
          margin-top: 5.4rem;
          margin-left: -.6rem;
        `}
      >
        Chmura
      </Title>
      <Description>
        Nie ważne gdzie jesteś.
        <br/>
        Twoje faktury są zawsze z Tobą
      </Description>
    </ElementItem>

    <ElementItem>
      <Image
        css={`
          margin-top: -.1rem;
        `}
        src={securityImg}
      />
      <Title
        css={`
          margin-top: 5rem;
          margin-left: -.5rem;
        `}
      >
        Bezpieczeństwo
      </Title>
      <Description>
        Nie musisz udostępniać hasła<br/>
        innymi osobom, zaproś ich<br/>
        jako dodatkowych użytkowników.
      </Description>
    </ElementItem>

    <ElementItem>
      <Image
        css={`
          margin-top: .2rem;
        `}
        src={robustnessImg}
      />
      <Title
        css={`
          margin-top: 5.4rem;
          margin-left: -.5rem;
        `}
      >
        Stabilność
      </Title>
      <Description>
        Wprowadzamy tylko sprawdzone i <br/>
        przetestowane rozwiązania.
      </Description>
    </ElementItem>
  </Container>
);

import * as React from 'react';
import { noop } from '../../../../../helpers/noop';
import { Spinner } from '../../../../Elements/Spinner/Spinner';
import { styled } from '../../../../Layout/ThemeProvider/ThemeProvider';
import { ComponentSize } from '../../../ComponentSize';
import { ButtonElement, RightComponentContainer } from './Button-styled';

const ButtonSpinner = styled(Spinner)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export type ButtonLook = 'default' | 'flat' | 'outline' | 'flat-transparent' | 'outline-transparent';

export interface ButtonProps {
  id?: string;
  name?: string;
  tabIndex?: number;
  secondary?: boolean;
  tertiary?: boolean;
  defaultWidth?: boolean;
  look?: ButtonLook;
  size?: ComponentSize;
  IconComponent?: any;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button';
  'data-testid'?: string;
  children?: React.ReactNode;

  componentRenderer?(): React.ReactNode;

  onClick?: (params: any) => any;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    look, size, loading, disabled, secondary, tertiary, componentRenderer: rightComponentRenderer,
    className, style, type, onClick, children, IconComponent, defaultWidth,
    ...htmlButtonElementProps
  } = props;

  const [spinnerColor, setSpinnerColor] = React.useState<string | undefined>(undefined);
  const [spinnerSize, setSpinnerSize] = React.useState<number | undefined>(undefined);

  const innerRef = React.useRef<HTMLButtonElement>(null);

  React.useImperativeHandle<HTMLButtonElement | null, HTMLButtonElement | null>(
    ref,
    () => innerRef.current,
    [innerRef],
  );

  React.useEffect(() => {
    if (innerRef.current) {
      const buttonStyles = getComputedStyle(innerRef.current);

      setSpinnerColor(buttonStyles.color);
      setSpinnerSize(Math.min(
        parseInt(buttonStyles.height, 10),
        parseInt(buttonStyles.width, 10),
      ) / 2);
    }
  }, []);

  return (
    <ButtonElement
      ref={innerRef}
      style={style}
      className={className}
      type={type}
      look={look || 'default'}
      size={size || 'medium'}
      defaultWidth={defaultWidth || false}
      secondary={secondary || false}
      tertiary={tertiary || false}
      disabled={disabled || false}
      loading={loading || false}
      onClick={(!loading && !disabled && onClick) ? onClick : noop}
      data-testid={props['data-testid']}
      {...htmlButtonElementProps}
    >
      {IconComponent && (
        <IconComponent/>
      )}
      {rightComponentRenderer && (
        <RightComponentContainer>
          {rightComponentRenderer()}
        </RightComponentContainer>
      )}
      {children}
      {loading && (
        <ButtonSpinner
          {...(spinnerColor ? { singleColor: spinnerColor } : {})}
          {...(spinnerSize ? { size: spinnerSize } : {})}
        />
      )}
    </ButtonElement>
  );
});

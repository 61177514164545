import {
  ImageConstantlyDeveloped,
  ImageEasyAndFastInHandling,
  ImageEveryPlaceEveryDevice,
} from 'components/src/components/Elements/TextImageSection/assets';
import { TextImageSection } from 'components/src/components/Elements/TextImageSection/TextImageSection';
import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';
import { Hero } from '../components/Hero/Hero';
import Layout from '../components/Layout/Layout';
import { AdditionalBenefits } from '../components/AdditionalBenefits/AdditionalBenefits';

const shorts = [
  {
    header: <>W każdym miejscu,<br/>na każdym urządzeniu</>,
    text: <>
      FAKTURUJ.TO to prosty i intuicyjny program do wystawiania<br/>
      faktur oraz przygotowywania raportów kasowych oparty w<br/>
      całości na pracy w Twojej przeglądarce.</>,
    imageSrc: ImageEveryPlaceEveryDevice,
  },
  {
    header: <>Proste i szybkie<br/>w obsłudze</>,
    text: <>Niezależnie czy chcesz wystawić fakturę sprzedaży czy<br/>
      fakturę zaliczkę, zawsze zrobisz to w sposób prosty i<br/>
      ekspresowy, bez zbędnych instalacji czy długich konfiguracji.</>,
    imageSrc: ImageEasyAndFastInHandling,
    textFirst: true,
  },
  {
    header: <>Stale rozwijane</>,
    text: <>Cały czas rozwijamy się dla Ciebie pracując nad nowymi<br/>
      funkcjonalnościami, które sukcesywnie będą dodawane do<br/>
      istniejących pakietów.</>,
    imageSrc: ImageConstantlyDeveloped,
  },
];

const Heading2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.robotoBold}, sans-serif;
  font-size: 4.8rem;
  letter-spacing: 0;
  color: #062861;
  text-align: center;
  opacity: 1;
  margin-bottom: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
`;

const IndexPage = () => (
  <Layout>
    <Hero
      css={`
        margin-bottom: 18.5rem;
      `}
    />
    <Heading2>
      Najważniejsze
    </Heading2>
    {shorts.map((short, idx, list) => {
      const isFirstItem = idx === 0;
      const isLastItem = idx < (list.length - 1);

      return (
        <TextImageSection
          style={{
            marginTop: isFirstItem ? '15rem' : 0,
            marginBottom: isLastItem ? '12rem' : '21.5rem',
          }}
          header={short.header}
          text={short.text}
          imageSrc={short.imageSrc}
          textFirst={short.textFirst}
        />
      );
    })}
    <Heading2>Pozostałe korzyści</Heading2>
    <AdditionalBenefits/>
  </Layout>
);

export default IndexPage;

import { Theme } from '../../../Layout/ThemeProvider/ThemeProvider';
import { ComponentSize } from '../../ComponentSize';
import { ButtonLook } from './Button/Button';

export const buttonSizeStyles = (size: ComponentSize) => ({
  xsmall: `
  height: 3.2rem;
  padding: .7rem 1.6rem;
  font-family: Quicksand, sans-serif;
  font-size: 1.2rem;
  justify-content: center;
  `,
  small: `
  height: 4rem;
  padding: .7rem 1.6rem;
  `,
  medium: 'padding: 1.5rem 3.8rem;',
}[size]);

type ButtonLookStylesParams = {
  look: ButtonLook;
  theme: Theme;
  secondary: boolean;
  tertiary: boolean;
  disabled: boolean;
};

export const buttonLookStyles = ({ look, theme, secondary, tertiary, disabled }: ButtonLookStylesParams) => ({
  default: `
    color: ${theme.colors.white};
    background-color: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};

    & > svg path {
      fill: ${theme.colors.white};
    }

    ${!disabled ? `
      &:active {
        background-color: ${theme.colors.primary5};
      }
    ` : `
      background-color: ${theme.colors.disabled};
    `}
  `,
  flat: `
    color: ${secondary ? theme.colors.secondary13 : theme.colors.primary8};
    background-color: ${theme.colors.primary9};

    & > svg path {
      fill: ${secondary ? theme.colors.secondary13 : theme.colors.primary8};
    }

    ${!disabled ? `
      &:active {
        color: ${theme.colors.primary5};

        & > svg path {
          fill: ${theme.colors.primary5};
        }
      }
    ` : `
      color: ${theme.colors.disabled};

      & > svg path {
        fill: ${theme.colors.disabled};
      }
    `}
  `,
  'flat-transparent': `
    color: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};
    background-color: transparent;

    & > svg path {
      fill: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};
    }

    ${!disabled ? `
      &:active {
        color: ${theme.colors.primary5};

        & > svg path {
          fill: ${theme.colors.primary5};
        }
      }
    ` : `
      color: ${theme.colors.disabled};

      & > svg path {
        fill: ${theme.colors.disabled};
      }
    `}
  `,
  outline: `
    color: ${secondary ? theme.colors.secondary13 : tertiary ? theme.colors.secondary17 : theme.colors.primary3};
    background-color: ${tertiary ? theme.colors.secondary14 : theme.colors.white};
    border: .1rem solid ${
    secondary ? theme.colors.secondary13 : tertiary ? theme.colors.secondary18 : theme.colors.primary3};

    & > svg path {
      fill: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};
    }

    ${!disabled ? `
      &:hover, &.highlighted {
        color: ${secondary ? theme.colors.secondary13 : tertiary ? theme.colors.primary10 : theme.colors.primary3};
        border: .1rem solid ${secondary
    ? theme.colors.secondary13
    : tertiary
      ? theme.colors.primary10
      : theme.colors.primary3
  };
      }

      &:active, &.active {
        color: ${tertiary ? theme.colors.white : theme.colors.primary5};
        border-color: ${tertiary ? theme.colors.primary10 : theme.colors.primary5};
        background-color: ${tertiary ? theme.colors.primary10 : theme.colors.white};

        & > svg path {
          fill: ${tertiary ? theme.colors.white : theme.colors.primary5};
        }
      }
    ` : `
      color: ${theme.colors.disabled};
      border-color: ${theme.colors.disabled};

      & > svg path {
        fill: ${theme.colors.disabled};
      }
    `}
  `,
  'outline-transparent': `
    color: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};
    background-color: transparent;
    border: 1px solid ${secondary ? theme.colors.secondary13 : theme.colors.primary3};

    & > svg path {
      fill: ${secondary ? theme.colors.secondary13 : theme.colors.primary3};
    }

    ${!disabled ? `
      &:active {
        color: ${theme.colors.primary5};
        border-color: ${theme.colors.primary5};

        & > svg path {
          fill: ${theme.colors.primary5};
        }
      }
    ` : `
      color: ${theme.colors.disabled};
      border-color: ${theme.colors.disabled};

      & > svg path {
        fill: ${theme.colors.disabled};
      }
    `}
  `,
}[look]);

export const buttonDefaultWidth = (defaultWidth: boolean) => `
  ${defaultWidth ? 'width: 24.5rem;' : ''}
`;

export const buttonDisabledStyles = (disabled: boolean) => `
  ${disabled ? 'cursor: not-allowed;' : ''}
`;

export const buttonLoadingStyles = (loading: boolean) => `
  ${loading
  ? `
  color: transparent !important;
  > svg {
    opacity: 0 !important;
  }
  `
  : ''}
`;
